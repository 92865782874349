@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@layer base {
  [type="text"],
  [type="email"],
  [type="url"],
  [type="password"],
  [type="number"],
  [type="date"],
  [type="datetime-local"],
  [type="month"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="week"],
  [multiple],
  textarea,
  select {
    @apply bg-white dark:bg-neutral-900 border-neutral-200 dark:border-neutral-700 rounded-full focus:ring-indigo-500 
    focus:border-indigo-500 block w-full sm:text-sm text-neutral-900 dark:text-neutral-200;
  }
}

@media (max-width: 1023px) {
  .nc-SectionHowItWork {
    margin-top: 100px !important;
  }

  .email {
    margin-top: 80px !important;
  }
}

@media (max-width: 767px) {
}

@media (max-width: 639px) {
  .headingTitle {
    text-align: center !important;
  }

  .headingSubTitle {
    font-size: 1.25rem !important;
  }

  .videoSubTitle {
    font-size: 1.25rem !important;
    margin-bottom: 20px !important;
  }

  .faqSubTitle {
    font-size: 1.25rem !important;
  }
}

[type='checkbox']:checked {
    background-image: none;
    position: relative;
}

[type='checkbox']:checked::before {
    content: "\f00c";
    font-family: "Line Awesome Free";
    font-weight: 900;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    color: black;
}

.dark [type='checkbox']:checked::before {
  content: "\f00c";
  font-family: "Line Awesome Free";
  font-weight: 900;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  color: white;
  background-color: transparent !important;
}

[type='checkbox']:checked,
[type='checkbox']:checked:hover,
[type='checkbox']:checked:focus {
    border-color: #e5e5e5 !important;
}

.dark [type='checkbox']:checked,
.dark [type='checkbox']:checked:hover,
.dark [type='checkbox']:checked:focus {
    border-color: #374151 !important;
}

.rc-slider-track {
  background-color: #d1d1d1 !important;
}

.dark .rc-slider-track {
  background-color: #1F2937 !important;
}

.rc-slider-handle:hover {
  border-color: #e9e9e9 !important;
}

.dark .rc-slider-handle:hover {
  border-color: #374151 !important;
}

.rc-slider-handle-dragging {
  box-shadow: 0 0 0 5px #e9e9e9 !important;
}

.dark .rc-slider-handle-dragging {
  box-shadow: 0 0 0 5px #374151 !important;
}

.rc-slider-handle {
  border-color: #e9e9e9 !important;
  opacity: 1 !important;
}

.dark .rc-slider-handle {
  border-color: #374151 !important;
}

.rc-slider-handle:focus-visible {
  box-shadow: 0 0 0 3px #e9e9e9 !important;
}

.dark .rc-slider-handle:focus-visible {
  box-shadow: 0 0 0 3px #374151 !important;
}

.rc-slider-rail {
  background-color: #e9e9e9 !important;
}

.dark .rc-slider-rail {
  background-color: #374151 !important;
}

[type="text"]:is(.dark *), [type="email"]:is(.dark *), [type="url"]:is(.dark *), [type="password"]:is(.dark *), [type="number"]:is(.dark *), [type="date"]:is(.dark *), [type="datetime-local"]:is(.dark *), [type="month"]:is(.dark *), [type="search"]:is(.dark *), [type="tel"]:is(.dark *), [type="time"]:is(.dark *), [type="week"]:is(.dark *), [multiple]:is(.dark *), textarea:is(.dark *), select:is(.dark *) {
  background-color: transparent !important;
}

.iti {
  width: 100%;
}

.iti__tel-input {
  @apply block w-full border-neutral-200 focus:border-neutral-200 focus:ring-neutral-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-neutral-200 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3;
  padding-left: 82px;
}

.iti__tel-input[data-country-code-length="4"] {
  padding-left: 90px;
}

.iti__selected-flag,
.iti__selected-country,
.iti__selected-country-primary {
  background-color: transparent !important;
}

.dark .iti__selected-flag,
.dark .iti__selected-country,
.dark .iti__selected-country-primary {
  background-color: transparent !important;
}

.iti__tel-input[data-country-code-length="4"] {
  padding-left: 90px;
}

.iti__search-input {
  outline: none !important;
  box-shadow: none !important;
}

.iti__search-input::placeholder {
  content: "Ara";
}